<template>
  <v-list two-line v-bind="$attrs">
    <v-progress-linear v-if="loading" indeterminate color="primary" />
    <template v-else>
      <component
        :is="item.type"
        v-for="(item, index) in items"
        :key="index"
        v-testid="`base-list-item-${index}`"
        v-bind="item.props"
        v-on="item.listeners"
      />
      <infinite-loading v-if="infinite" @infinite="$emit('infinite', $event)">
        <div slot="spinner">
          <v-progress-circular
            class="mt-2"
            size="25"
            width="2"
            indeterminate
            color="primary"
          />
        </div>
        <div slot="no-more" />
        <div slot="no-results">No data found</div>
      </infinite-loading>
    </template>
  </v-list>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
export default {
  name: 'BaseList',
  components: {
    InfiniteLoading
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    infinite: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
